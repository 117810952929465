// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { sqWorkbenchStore } from '@/core/core.stores';
import { setCurrentUser, setDarkMode, setPreferNewTab, setUserTimeZone } from '@/workbench/workbench.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqUsersApi, UserInputV1 } from '@/sdk';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { switchLanguage } from '@/utilities/i18n.utilities';
import { LOCALE_DATA, LOCALES } from '@/utilities/i18n.constants';

interface EditUserProfileProps {
  closeFn: (resetDarkMode?: boolean) => void;
  toggleFn: () => void;
}

export const EditUserProfile: React.FunctionComponent<EditUserProfileProps> = ({ closeFn, toggleFn }) => {
  const { t } = useTranslation();

  const { currentUser: user, userTimeZone, userLanguage, preferNewTab, darkMode } = useFlux(sqWorkbenchStore);

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [doUseDarkMode, setDoUseDarkMode] = useState(darkMode);

  const isUserSSO = !user.isPasswordSettable;
  const isCancelEnabled = userTimeZone && user.firstName && user.lastName && user.email;

  const formattedLanguageOptions = _.map(LOCALE_DATA, (language) => ({
    text: language.text,
    value: language.key,
  }));

  const selectedLanguage = _.find(formattedLanguageOptions, {
    value: userLanguage,
  });

  const updateUser = (values) => {
    const { firstName, lastName, email, timezone, language, preferNewTab, newPassword } = values;
    const userProps = _.pick(values, ['email', 'firstName', 'lastName']);
    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;
    if (darkMode !== values.userDarkMode) {
      setDarkMode(values.userDarkMode);
    }

    _.assign(userProps, { name });

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the end
     * user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username.
     */
    if (!isUserSSO) {
      _.assign(userProps, { username: email });
    }

    if (editingPassword) {
      return (
        sqUsersApi
          .updatePassword({ currentPassword, newPassword }, { id: user.id })
          // We assume that authProvider is Seeq because only those users can change their password.
          .then(() => successToast({ messageKey: 'USER.PASSWORD_CHANGED' }))
          .then(() => closeFn())
          .catch((error) => errorToast({ httpResponseOrError: error }))
          .finally(() => {
            setEditingPassword(false);
          })
      );
    }

    return sqUsersApi
      .updateUser(userProps as UserInputV1, { id: user.id })
      .then(() => setCurrentUser())
      .then(() => setUserTimeZone(timezone))
      .then(() => setPreferNewTab(preferNewTab))
      .then(() => switchLanguage(language?.value ?? LOCALES.EN))
      .then(() => successToast({ messageKey: 'USER.PROFILE_UPDATED' }))
      .then(() => closeFn())
      .catch((error) => errorToast({ httpResponseOrError: error }));
  };

  const displayEditUser = () => setEditingPassword(false);

  const passwordChangeForm: FormElement = {
    component: 'FormGroup',
    name: 'editPassword',
    includeIf: editingPassword,
    components: [
      {
        component: 'FormControlFormComponent',
        name: 'currentPassword',
        label: 'USER.CURRENT_PASSWORD',
        value: currentPassword,
        onChange: (value: string) => setCurrentPassword(value),
        size: 'md',
        includeIf: !user.isAdmin,
        type: 'password',
        testId: 'currentPassword',
      },
      {
        component: 'PasswordGroupFormComponent',
        name: 'passwordFormGroup',
        value: 'passwordFormGroup',
      },
    ],
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userProfile',
      includeIf: !editingPassword,
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'firstName',
          label: 'USER.FIRST_NAME',
          value: user.firstName,
          required: true,
          onChange: _.noop,
          size: 'md',
          testId: 'firstName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'lastName',
          label: 'USER.LAST_NAME',
          value: user.lastName,
          required: true,
          onChange: _.noop,
          size: 'md',
          testId: 'lastName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'email',
          // For non-SSO users make it clear that the email field is also the username
          label: isUserSSO ? 'USER.EMAIL' : 'USER.USERNAME_AND_EMAIL',
          // For non-SSO users with no email set (such as 'agent_api_key') show the username to make it clear
          // that editing the field would change the username
          value: isUserSSO ? user.email : user.email || user.username,
          required: true,
          onChange: _.noop,
          size: 'md',
          testId: 'email',
        },
        {
          component: 'TimeZoneSelectorFormComponent',
          name: 'timezone',
          label: 'USER.TIME_ZONE',
          value: userTimeZone || sqTimezones.defaultTimezone,
          onChange: _.noop,
          defaultTimeZone: sqTimezones.defaultTimezone,
          skipStore: true,
          testId: 'timezone',
        },
        {
          component: 'IconSelectFormComponent',
          name: 'language',
          label: 'USER.LANGUAGE.SELECTION',
          value: selectedLanguage,
          onChange: _.noop,
          selectOptions: formattedLanguageOptions,
          insideModal: true,
          skipStore: true,
          testId: 'language',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'userPreferNewTab',
          name: 'preferNewTab',
          label: '',
          onChange: _.noop,
          skipStore: true,
          checkboxLabel: 'USER.PREFER_NEW_TAB',
          value: preferNewTab,
        },
        {
          component: 'CheckboxFormComponent',
          id: 'userDarkMode',
          name: 'userDarkMode',
          label: '',
          onChange: () => {
            requestAnimationFrame(toggleFn);
            setDoUseDarkMode(!doUseDarkMode);
          },
          skipStore: true,
          checkboxLabel: 'DARK_MODE.ENABLE',
          value: doUseDarkMode,
        },
        {
          component: 'LabelFormComponent',
          name: 'passwordReset',
          value: '',
          extraClassNames: 'text-bolder',
        },
        {
          component: 'ClickableLinkFormComponent',
          name: 'setPassword',
          includeIf: user.isPasswordSettable,
          onChange: _.noop,
          value: 'USER.CHANGE_PASSWORD',
          icon: 'fa-lock',
          linkAction: () => setEditingPassword(true),
        },
      ],
    },
    passwordChangeForm,
  ];

  const onClose = () => {
    // if a user clicks the cancel button we need to make sure that we
    // toggle the darkmode display back to the intended display
    if (doUseDarkMode !== darkMode) {
      toggleFn();
    }
    closeFn();
  };

  return (
    // The close and cancel buttons get hidden when the user has not set their timezone to require the user
    // to save their profile, which will set their timezone
    // The close and cancel buttons also get hidden for SSO users that are missing their first name,
    // last name, or email address to require the user to set those attributes
    <Modal show={true} onHide={onClose} animation={false} data-testid="editUserProfile">
      <Modal.Header closeButton={isCancelEnabled}>
        <h3 className="mr-auto">{editingPassword ? t('USER.CHANGE_PASSWORD') : t('EDIT_PROFILE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserProfile">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            submitFn={updateUser}
            closeFn={editingPassword ? displayEditUser : () => onClose()}
            hideCancel={!isCancelEnabled}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
